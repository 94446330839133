import React, { useEffect, useState } from "react";
import CategoryFilter from "./components/CategoryFilter";
import ProjectsList from "./components/ProjectsList";
import { useIntl } from "react-intl";
import "./styles/SearchProjectsByCategory.scss";
import { getCurrentFilterParam } from "./constants/getCurrentFilterParam";
import { FILTER_KEY_BY_PARAM } from "./constants/filterKeyByParam";
import IconDeleteFilter from "./img/IconDeleteFilter.svg"
import IconDeleteAllFilter from "./img/IconDeleteAllFilters.svg"

const SearchProjectsByCategory = ({ history, location }) => {
  const intl = useIntl();
  const [currentFilterParam, setCurrentFilterParam] = useState("");
  const [filterValues, setFilterValues] = useState({
    products: [],
    instances: [],
    cityV2: "",
    authorizations: [],
    marketSegments: [],
    keyword: "",
    lang: intl.locale,
  });

  const [filterValuesComplete, setFilterValuesComplete] = useState([]);

  const formatFilterParams = () => {
    const searchParams = new URLSearchParams(location.search);
    let obj = {};

    searchParams.forEach((value, key) => {
      let parsedValue;

      try {
        parsedValue = JSON.parse(value);
        parsedValue = Array.isArray(parsedValue) ? parsedValue : value;
      } catch (e) {
        parsedValue = value; // Use the original value if a parsing error occurs
      }

      obj = {
        [FILTER_KEY_BY_PARAM[key]]:
          key !== FILTER_KEY_BY_PARAM.keyword
            ? Array.isArray(parsedValue)
              ? [...parsedValue]
              : [parsedValue]
            : value,
      };
    });

    return obj;
  };

  const handleCategoriesSelected = (e, instancesGroup, currentOption) => {

    if (location.search) {
      history.push({
        pathname: location.pathname,
        search: "",
      });
    }

    const { name, value, checked, id } = e.target;
    const isCityFilter = name === "city";

    const instances = instancesGroup.length !== 0 && JSON.parse(value);

    setFilterValues((prevState) => ({
      ...prevState,
      [name]: checked
        ? prevState[name]
          ? instances
            ? [...prevState[name], ...instances]
            : [...prevState[name], value]
          : isCityFilter
          ? value
          : [value] // Validation city filter because this is a string instead array
        : instances
        ? (prevState[name] || []).filter((item) => !instances.includes(item))
        : isCityFilter
        ? (prevState[name] = "")
        : (prevState[name] || []).filter((item) => item !== value),
      keyword: "",
    }));

    setFilterValuesComplete((prev) =>
      checked
        ? [...prev, { group: name, value, checked, id, currentOption }]
        : prev.filter((filter) => filter.value !== value)
    );
  };

  const removeFilter = (filterValue) => {
    setFilterValuesComplete((prev) =>
      prev.filter((filter) => filter.value !== filterValue)
    );
    setFilterValues((prev) => {
      const updatedFilters = { ...prev };
      Object.keys(updatedFilters).forEach((key) => {
        if (Array.isArray(updatedFilters[key])) {
          updatedFilters[key] = updatedFilters[key].filter((item) => item !== filterValue);
        } else if (updatedFilters[key] === filterValue) {
          updatedFilters[key] = "";
        }
      });
      return updatedFilters;
    });
  };

  const clearAllFilters = () => {
    setFilterValues({
      products: [],
      instances: [],
      cityV2: "",
      authorizations: [],
      marketSegments: [],
      keyword: "",
      lang: intl.locale,
    });
    setFilterValuesComplete([]);
  };

  useEffect(() => {
    const formattedParams = formatFilterParams();
    const filterParam = getCurrentFilterParam(formattedParams, location, intl);

    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      ...formattedParams,
    }));
    setCurrentFilterParam(filterParam);
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const hasSearchParams = searchParams.toString() !== "";
    if (!hasSearchParams)
      setCurrentFilterParam(
        intl.formatMessage({ id: "Projects.Home.category.view.option.all-projects" })
      );
  }, []);

  return (
    <div>
      <div className="container">
        <div className="d-flex">
          <div className="search--projects--by__category-title">
            <h2 className="font-weight-bold text-uppercase text-secondary">
              {currentFilterParam}
            </h2>
          </div>
        </div>

        <div className="custom_class_main_container_row_filters">
          <CategoryFilter
            filters={filterValues}
            handleCategoriesSelected={handleCategoriesSelected}
          />

          <div className="main_content_indivisual_filters">

            <div className="col1_indivisual_filters">
              {filterValuesComplete.map((filter, index) => (
                <div
                  key={`${filter.group}-${index}`}
                  className="row-filter-item"
                >
                  <img 
                    src={IconDeleteFilter} 
                    alt="delete filter" 
                    onClick={() => removeFilter(filter.value)} 
                    className="custom_class_delete_filter"
                  />
                    {filter?.currentOption?.name}
                </div>
              ))}
            </div>

            {filterValuesComplete.length > 0 && (
              <div className="col2_indivisual_filters">
                  <button
                    className="custom_class_clear_filters_btn"
                    onClick={clearAllFilters}
                  >
                    Clear filters
                    <img
                      src={IconDeleteAllFilter} 
                      alt="delete filter" 
                    />
                  </button>
              </div>
            )}

          </div>
        </div>

        <ProjectsList filters={filterValues} />
      </div>
    </div>
  );
};

export default SearchProjectsByCategory;
