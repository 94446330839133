import gql from "graphql-tag";

export const projectsQuery = gql`
  query projects(
    $products: [ID]
    $instances: [ID]
    $cityV2: ID
    $authorizations: [ID]
    $marketSegments: [String]
    $keyword: String
    $lang: String
  ) {
    promotedProjects(
      products: $products
      instances: $instances
      cityV2: $cityV2
      authorizations: $authorizations
      marketSegments: $marketSegments
      keyword: $keyword
      lang: $lang
    ) {
      edges {
        node {
          id
          slug
          title
          image
          marketSegment
          products {
            edges {
              node {
                id
                name
              }
            }
          }
          city
          relatedProjects {
            edges {
              node {
                id
                slug
                image
                title
                city
                products {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          authorization
        }
      }
    }
  }
`;
