import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import "./styles/CategoryAccordion.scss";
import { useFilterOptions } from "./hooks/useFilterOptions";
import IconArrowdown from "./img/Icon-Arrowdown.svg"

const CategoryAccordion = ({
  titleCategory,
  name,
  options,
  filters,
  handleCategoriesSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { filterOptions, handleMixInstances } = useFilterOptions(options, name);

  const handleClick = () => setIsOpen(!isOpen);

  const handleChangeFiltersCheckbox = (e, currentOption) => {
    const instancesGroup = handleMixInstances(currentOption);

    if (instancesGroup.length !== 0) {
      e.target.value = JSON.stringify(instancesGroup);
    }
    
    handleCategoriesSelected(e, instancesGroup, currentOption);
  }

  const handleCheckedOption = (option) => {
    const isArray = typeof filters[name] === "object"
    if (isArray) {
      return filters[name].find((val) => val !== "authorizations" && val === option.id)
    } else {
      return filters[name] === option.name
    }
  }

  return (
    <div id="accordion" className="custom_class_accordion_filter">
      <div className="category-accordion-container">
        <p className="category-accordion-title m-0" onClick={handleClick}>
          {titleCategory}
          <img src={IconArrowdown} alt="IconArrowdown" 
            className={`custom_class_icon_dropdown ${!isOpen  ? "OpenRotate": "CloseRotate"}`}
          />
        </p>
        <Collapse isOpen={isOpen}>
          {filterOptions?.map((option) => (
            <div key={`item_${option.id}`} className="custom_class_container_option_filter">
              <label className="custom_class_label cursor-pointer p-0 m-0">
                <input
                  name={name}
                  type={"checkbox"}
                  value={option.id}
                  id={option.name}
                  checked={handleCheckedOption(option)}
                  className=""
                  onChange={(e) => handleChangeFiltersCheckbox(e, option)}
                />
                <p className="category-accordion-option">
                  {option.name}
                </p>
              </label>
            </div>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default CategoryAccordion;
